main{
  min-height: 80vh;
}

h1{
  font-size: 1.8rem;
  padding: 1rem 0;
}

h2{
  font-size: 1.4rem;
  padding: 0.5rem 0;
}

h3{
  padding: 1rem 0;
}

.rating span{
  margin: 0.1rem;
}

/* carousel */
.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
  display: flex;
}
.carousel-caption {
  position: absolute;
  top: 0;
}

.carousel-caption h4 {
  color: #fff;
}

.carousel img {
  display:block;
  height: 300px;
  padding: 30px;
  margin: 40px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
}

.carousel a {
  margin: 0 auto;
}

@media (max-width: 900px) {
  .carousel-caption h2 {
    font-size: 2.5vw;
  }
}

/* ---------------------       my css       --------------------- */

/* inline row for the search box and it's button */
.inline_row{
  display:flex; 
  flex-direction:row;
}

.search_button {
  padding-left: 1rem;
}

.nav_items {
  margin-left: auto;
}

/* whatsapp button */

.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* whatsapp button for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}

.copy_button {
  cursor: pointer;
  border: none;
  color: #fff;
  background-color:  rgb(53, 54, 58);
  display: inline-block;
  font-weight: 400;
  padding: 0.8rem 1rem;
  font-size: 1.3rem;
  line-height: 1.5;
  border-radius: 1rem;
  width: 100%;
  margin-top: 1em;
}

.copy_button:hover {
  background: #82db5c;
  color: rgb(63, 62, 62);
}

.register_ml_product_label {
  margin: 10px 0px 0px 25px;
}


.mySelect[class*="-container"] {
  background-color: lemonchiffon;
  padding: 10px;
  height: 200px;
}


/* main {
  background-color: red;
} */